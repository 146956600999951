import request from '@/utils/request'
import { apiUrl } from '@/config/parameters'
export default {
  //获取拨打电话的状态码对应
  checkPhoneEmpty: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/business/checkPhoneEmpty`, {
      params: params,
    })
  },
  // -----------------------------线索板块---------------------------------
  // 获取线索
  getlist: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/clue/getlist`, {
      params: params,
    })
  },
  // 添加线索
  add: (params = {}) => {
    return request.post(`${apiUrl.ACG_SEVICEURL}api/clue/saveclue`, params)
  },
  //修改线索
  editClue: (params = {}) => {
    return request.post(`${apiUrl.ACG_SEVICEURL}api/clue/editClue`, params)
  },
  // 推送线索
  sendclue: (params = {}) => {
    return request.post(`${apiUrl.ACG_SEVICEURL}api/wechat/sendclue`, params)
  },
  // 推送自定义信息
  send: (params = {}) => {
    return request.post(`${apiUrl.ACG_SEVICEURL}api/wechat/send`, params)
  },
  // 推送记录
  getrecord: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/push/getrecord`, {
      params: params,
    })
  },
  //获取商机反馈列表
  getappealList: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getappealList`, {
      params: params,
    })
  },
  //审核接口
  updateAppealstatus: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/business/updateAppealstatus`, {
      params: params,
    })
  },
  //图片
  uploadImage: (params = {}) => {
     return request.post(`${apiUrl.ACG_SEVICEURL}api/business/uploadImage`, params)
  },
  // 获取城市数据
  getPurchaseArea: (params = {}) => {
      return request.get(
        `${apiUrl.ACG_SEVICEURL}api/business/getPurchaseArea`,
        {
          params: params,
        }
      )
  },
  //获取默认价格
  getDefaultPrice: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getDefaultPrice`, {
      params: params,
    })
  },
  //设置默认价格
  setDefaultPrice: (params = {}) => {
    return request.post(`${apiUrl.ACG_SEVICEURL}api/business/setDefaultPrice`, params)
  },
  //会员财务记录
  getVIPFinancialRecord: (params = {}) => {
   return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getVIPFinancialRecord`, {
      params: params,
   })
  },
  //获取充值记录
  getRechargeRecordList: (params = {}) => {
   return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getRechargeRecordList`, {
     params: params,
   })
  },
  //人工充值
  backendRecharge: (params = {}) => {
    return request.post(`${apiUrl.ACG_SEVICEURL}api/business/backendRecharge`, params)
  },
  //获取微信商机支付
  getPaymentRecord: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getPaymentRecord`, {
      params: params,
    })
   },
   //获取新闻类目
   getNewsCategory: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getNewsCategory`, {
      params: params,
    })
   },
    //获取新闻列表
    getNewsList: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getNewsList`, {
        params: params,
      })
     },
    //保存新闻
    saveNews: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveNews`, params)
    },
    //删除新闻
    delNews: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/delNews`, {
        params: params,
      })
     },
    //获取修改详情
    getNewsDetail: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getNewsDetail`, {
        params: params,
      })
     },
     //获取关于我们和用户协议
     getAboutUs: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getAboutUs`, {
        params: params,
      })
    },
    //修改关于我们和用户协议
    saveAboutUs: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveAboutUs`, params)
    },
    savephone: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/savephone`, params)
    },
    //审核接口
    updateAppealstatus: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/updateAppealstatus`, {
        params: params,
      })
    },
    //获取会员列表
    getMemberList: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getMemberList`, {
        params: params,
      })
    },
    //推送会员
    sendClueByMember: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/sendClueByMember`, {
        params: params,
      })
    },
    //删除商机
    delclue: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/clue/delclue`, {
        params: params,
      })
    },
    //意见反馈
    getOpinions: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getOpinions`, {
        params: params,
      })
    },
    //获取推送记录
    getPushRecord: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getPushRecord`, {
        params: params,
      })
    },
    //获取电话归属地
    getAreaByPhone: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getAreaByPhone`, {
        params: params,
      })
    },
    //审核商机
    auditbusiness: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/auditbusiness`, params)
    },
    //关键词订阅排名
    getKeywordsRank: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getKeywordsRank`, {
        params: params,
      })
    },
    //区域消费排名
    getBusinessRegionRank: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getBusinessRegionRank`, {
        params: params,
      })
    },
    //商机花费排名
    getBusinessExpendRank: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getBusinessExpendRank`, {
        params: params,
      })
    },
     //年度充值数据
     getMonthRecharge: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getMonthRecharge`, {
        params: params,
      })
    },
    //优电通线路
    getCallLine: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getCallLine`, {
        params: params,
      })
    },
    //优电通状态    
    getCallStatus: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getCallStatus`, {
        params: params,
      })
    },
    //获取设置
    getCallSet: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getCallSet`, {
        params: params,
      })
    },
    //保存设置
    saveCallSet: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveCallSet`, params)
    },
    //获取显示权限
    getPermission: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getPermission`, {
        params: params,
      })
    },
    //商机来源列表
    getPlatForm: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getPlatForm`, {
        params: params,
      })
    },
    //更改审核状态
    saveAuditOpen: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveAuditOpen`, params)
    },
    //获取提现记录列表
    GetWithdrawalApplicationRecord: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getWithdrawalRecord`, {
        params: params,
      })
    },
    //更改提现审核状态
    updateWithdrawalStatus: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/updateWithdrawalStatus`, {params:params})
    },
    //获取可选模板内容
    getTemplateContent: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getTemplateContent`, {
        params: params,
      })
    },
    //获取模板设置
    getTemplateSetting: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getTemplateSetting`, {
        params: params,
      })
    },
    //保存模板实则
    saveTemplateSetting: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveTemplateSetting`, params)
    },
    //获取关键词
    getKeywordSetting: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getKeywordSetting`, {
        params: params,
      })
    },
    //保存关键词
    saveKeywordSetting: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveKeywordSetting`, params)
    },
    //删除关键词设置
    delKeywordSetting: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/delKeywordSetting`, {
        params: params,
      })
    },
    //获取返利规则
     getRebateSetting: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getRebateSetting`, {
        params: params,
      })
    },
    //删除返利设置
    delRebateSetting: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/delRebateSetting`, {
        params: params,
      })
    },
    //保存返利规则
    saveRebateSetting: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveRebateSetting`, params)
    },
    //会员余额订阅词排名
    getBalanceRank: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getBalanceRank`, {
        params: params,
      })
    },
    //今日订阅词排名
    getKeywordToday: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getKeywordToday`, {
        params: params,
      })
    },
    //获取修改记录
    getUpdateRecord: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getUpdateRecord`, {
        params: params,
      })
    },  
    //添加会员订阅词
    addCustomizedKeyWord: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/addCustomizedKeyWord`, params)
    },
    //删除会员订阅词
    delBusKeyWord: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/delBusKeyWord`, {
        params: params,
      })
    }, 
    //获取审核状态
    getManualStatus: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getManualStatus`, {
        params: params,
      })
    }, 
    //修改审核状态
    saveManualStatus: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveManualStatus`, params)
    },
    //批量涨价/降价
    batchKeywordSetting: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/batchKeywordSetting`, params)
    },
    //获取商机信息
    getPersonalStatistics: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getPersonalStatistics`, {
        params: params,
      })
    }, 
    //获取审核信息
    getAuditStatistics: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getAuditStatistics`, {
        params: params,
      })
    }, 
    //获取项目设置
    getproductSet: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getproductSet`, {
        params: params,
      })
    },
    //保存项目设置
    addProductSet: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/addProductSet`, params)
    }, 
    //获取推荐词
    getrecommandSubWords: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getrecommandSubWords`, {
        params: params,
      })
    },
    //添加推荐词
    addRecommendSubKeywords: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/addRecommendSubKeywords`, {
        params: params,
      })
    },
    //删除推荐词
    delRecommendSubKeywords: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/delRecommendSubKeywords`, {
        params: params,
      })
    },
    //获取行业细分
    getBaiduIndustry: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getBaiduIndustry`, {
        params: params,
      })
    },
    //商机看板
    getBussinessBoard: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getBussinessBoard`, {
        params: params,
      })
    },
     //用户看板
     getUserBoard: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getUserBoard`, {
        params: params,
      })
    },
    // 财务看板
    getFinanceBoard: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getFinanceBoard`, {
        params: params,
      })
    },
    //审核看板
    getAuditBoard: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getAuditBoard`, {
        params: params,
      })
    },
    //推送看板
    getPushBoard: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getPushBoard`, {
        params: params,
      })
    },
    //客情看板
    getAppealBoard: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getAppealBoard`, {
        params: params,
      })
    },
    //保存会员信息
    updateMemberInfo: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/updateMemberInfo`, params)
    }, 
    //用户修改记录
    getUpdateMemberList: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getUpdateMemberList`, {
        params: params,
      })
    },
    //获取行业分类
    getcategory: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getcategory`, {
        params: params,
      })
    },
    
    //导出定制需求关键词接口
    getKeywordSettingAll: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getKeywordSettingAll`, {
        params: params,
      })
    },
    //获取命中关键词默认价格getHitPrice
    getHitPrice: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getHitPrice`, {
        params: params,
      })
    },
    //获取全部行业
    getallcategory: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getallcategory`, {
        params: params,
      })
    },
    //获取黑名单
    getPhoneBlacklist: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getPhoneBlacklist`, {
        params: params,
      })
    },
    //更改黑名单
    updatPhoneBlacklist: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/updatPhoneBlacklist`, params)
    }, 
    //获取全部地区
    getAllSetArea: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getAllSetArea`, {
        params: params,
      })
    },
    //获取上级
    gethigherLevelUserList: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/gethigherLevelUserList`, {
        params: params,
      })
    },
    //获取下级
    getLowlUserList: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getLowlUserList`, {
        params: params,
      })
    },
    //加入待审核
    savePendingAudit: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/savePendingAudit`, params)
    }, 
    //获取分词
    getsplitOrder: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getsplitOrder`, {
        params: params,
      })
    },
    //添加分词
    saveKeywordsSetting: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveKeywordsSetting`, params)
    }, 
    //获取跟进记录
    getUserTrackingRecord: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getUserTrackingRecord`, {
        params: params,
      })
    },
    //保存跟进记录
    saveUserTracking: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveUserTracking`, params)
    }, 
    //获取联系人职位
    getContackJobTitleList: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/jobtitlelist`, {
        params: params,
      })
    },
    //获取数据看板图表
    getBusinessReport: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getBusinessReport`, {
        params: params,
      })
    },
    //获取免审职员
    getExemptAuditEmployee: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getExemptAuditEmployee`, {
        params: params,
      })
    },
    //保存免审职员
    saveExemptAuditEmployee: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveExemptAuditEmployee`, params)
    }, 
    //获取审核报表
    getAuditReport: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getAuditReport`, {
        params: params,
      })
    },
    //获取极限词列表
    getLimitWord: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/clue/getLimitWord`, {
        params: params,
      })
    },
      //批量添加极限词
    addLimitWords: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/clue/addLimitWords`, params)
    }, 
    //批量删除极限词
    delLimitWord: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/clue/delLimitWord`, params)
    }, 
    //上架下架
    updateLimitWord: (params= {},query=0 ) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/clue/updateLimitWord?isUse=${query}`, params)
    }, 
    
    //下一条前查询
    getBusinessInfoToAudit: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/clue/getBusinessInfoToAudit`, {
        params: params,
      })
    },
    //会员操作记录
    getUserOperation: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getUserOperation`, {
        params: params,
      })
    },
    //获取关联词
    getWordPerformance: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getWordPerformance`, {
        params: params,
      })
    },
    //获取积分记录
    getPointList: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getPointList`, {
        params: params,
      })
    },
    //获取财务列表
    getFinancialList: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getFinancialList`, {
        params: params,
      })
    },
    
    //获取交易状态
    getTradeState: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getTradeState`, {
        params: params,
      })
    },
    //获取行业
    getIndustryCategory: (params = {}) => {
      return request.get(`${apiUrl.ACG_SEVICEURL}api/business/getIndustryCategory`, {
        params: params,
      })
    },
    //保存行业
    saveIndustryCategory: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/saveIndustryCategory`, params)
    }, 
     //保存行业
     delIndustryCategory: (params = {}) => {
      return request.post(`${apiUrl.ACG_SEVICEURL}api/business/delIndustryCategory`, params)
    }, 
    
    
    

    
  // 用户板块---------------------------------

  // 获取用户
  getuserlist: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/push/getuserlist?`, {
      params: params,
    })
  },
  // 编辑用户
  edituser: (params = {}) => {
    return request.post(`${apiUrl.ACG_SEVICEURL}api/push/edituser`, params)
  },
  // 删除用户
  deleteuser: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/push/deleteuser`, {
      params: params,
    })
  },
  // 同步用户
  getusertosave: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/wechat/getusertosave`, {
      params: params,
    })
  },
  // 获取单位
  getunit: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/push/getunit`, {
      params: params,
    })
  },
  // -----------------------------模板板块---------------------------------
  // 获取模板
  gettemplate: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/push/gettemplate`, {
      params: params,
    })
  },
  // 删除模板
  deletetemplate: (params = {}) => {
    return request.get(`${apiUrl.ACG_SEVICEURL}api/push/deletetemplate`, {
      params: params,
    })
  },
  // 同步模板
  getalltemplatetosave: (params = {}) => {
    return request.get(
      `${apiUrl.ACG_SEVICEURL}api/wechat/getalltemplatetosave`,
      {
        params: params,
      }
    )
  },
  // -----------------------------判断是否为电商登录---------------------------------
  // 同步模板
  // jsfunclueFn: window.jsfunclue.getLoginUser((res) => {
  //   return res
  // }),
}
